import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Col, Divider, Row, Space } from 'antd';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import useIsBalesMode from 'src/components/project/explore/hooks/useIsBalesMode';
import BalesTdsRow, {
  BalesTdsRowHeader
} from 'src/components/project/explore/supplier-list/BalesTdsRow';
import Tag, {
  BalesTags,
  Count,
  ResinTags
} from 'src/components/project/explore/supplier-list/Tag';
import TdsRow, {
  TdsRowHeader
} from 'src/components/project/explore/supplier-list/TdsRow';

const MAX_SHOW_SUPPLIERS = 3;

export default function SupplierRow({
  supplier,
  accepted,
  setSupplierAccepted,
  removed,
  setSupplierRemoved,
  resultDetails
}) {
  const [expanded, setExpanded] = useState(false);
  const types = useMemo(
    () => Array.from(new Set(supplier?.tds_list.map((t) => t.type))).join(', '),
    [supplier]
  );
  const forms = useMemo(
    () => Array.from(new Set(supplier?.tds_list.map((t) => t.form))).join(', '),
    [supplier]
  );
  const locations = formatLocationList(supplier.locations);

  const isBales = useIsBalesMode();

  if (!supplier) return null;

  const onClickAccept = (ev) => {
    if (supplier) setSupplierAccepted(supplier);
    ev.stopPropagation();
  };

  const onClickRemove = (ev) => {
    if (supplier) setSupplierRemoved(supplier);
    ev.stopPropagation();
  };
  const verified = resultDetails?.verified;

  const onSupplierClicked = (ev) => {
    ev.stopPropagation();
  };

  return (
    <div
      className={`explore-suppliers-table--panel ${expanded ? 'expanded' : ''}`}
    >
      <div
        role="presentation"
        className={`explore-suppliers-table--row ${
          expanded ? 'expanded' : ''
        } ${removed ? 'removed' : ''}`}
        onClick={() => setExpanded(!expanded)}
      >
        <Row
          gutter={8}
          className="explore-suppliers-table--row--content"
          align="stretch"
        >
          <Col span={8}>
            <div className="explore-suppliers-table--row--title">
              <Link
                to={`/supplier/${supplier.company_id}`}
                onClick={onSupplierClicked}
                target="_blank"
                className="supplier-link"
              >
                {supplier.company_name}{' '}
              </Link>
              {isBales && verified && <Tag text="Verified" type="primary" />}
            </div>
            <div>{locations}</div>
          </Col>
          <Col>
            <Count count={supplier.tds_list?.length} />
          </Col>
          <Col>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col span={4}>
            <div className="explore-suppliers-table--row--header">{types}</div>
            <div>{forms}</div>
          </Col>
          <Col>
            {isBales ? (
              <BalesTags supplier={supplier} />
            ) : (
              <ResinTags resultDetails={resultDetails} />
            )}
          </Col>
          <Col style={{ marginLeft: 'auto' }}>
            <Space direction="horizontal">
              <Button
                className={`accept-button ${
                  accepted || supplier.supplier_label === 'accepted'
                    ? 'accepted'
                    : ''
                }`}
                onClick={onClickAccept}
                size="small"
              >
                <CheckOutlined />
              </Button>
              <Button onClick={onClickRemove} size="small">
                <CloseCircleOutlined />
                {removed ? 'Restore' : 'Remove'}
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
      <div hidden={!expanded} className="explore-suppliers-table--tds-list">
        {isBales ? (
          <>
            <BalesTdsRowHeader tdsList={supplier.tds_list} />
            {supplier.tds_list.map((t) => (
              <BalesTdsRow key={t.tds_id} tds={t} />
            ))}
          </>
        ) : (
          <>
            <TdsRowHeader tdsList={supplier.tds_list} />
            {supplier.tds_list.map((t) => (
              <TdsRow key={t.tds_id} tds={t} />
            ))}
          </>
        )}
      </div>
    </div>
  );
}
SupplierRow.propTypes = {
  supplier: PropTypes.object,
  accepted: PropTypes.object,
  setSupplierAccepted: PropTypes.object,
  removed: PropTypes.object,
  setSupplierRemoved: PropTypes.object,
  resultDetails: PropTypes.object
};

function formatLocation(l) {
  return [l.city, l.country === 'United States' && l.state, l.country]
    .filter((a) => a)
    .join(', ');
}

function formatLocationList(locations) {
  const suffix =
    locations.length > MAX_SHOW_SUPPLIERS
      ? ` +${locations.length - MAX_SHOW_SUPPLIERS}`
      : '';
  return (
    <>
      {locations
        .slice(0, MAX_SHOW_SUPPLIERS)
        .map((l) => formatLocation(l))
        .join('; ')}
      <span className="location-plus_suffix">{suffix}</span>
    </>
  );
}
