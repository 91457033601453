import { Button, Radio } from 'antd';
import React from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

export default function CapacityFilter() {
  const [capacityFilter, setCapacityFilter, clearCapacityFilter] =
    useConciergeContextState(['explore', 'filters', 'capacity__gte']);

  return (
    <>
      <Radio.Group
        options={[
          { value: '22040', label: '>10 mt' },
          { value: '220400', label: '>100 mt' },
          { value: '2204000', label: '>1,000 mt' },
          { value: '22040000', label: '>10,000 mt' },
          { value: '220400000', label: '>100,000 mt' }
        ]}
        value={capacityFilter}
        onChange={({ target }) => setCapacityFilter(target.value)}
      />
      {capacityFilter && (
        <Button onClick={clearCapacityFilter} type="link" htmlType="button">
          Clear
        </Button>
      )}
    </>
  );
}
