import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Typography } from 'antd';
import VisibilityIcon from 'src/components/icons/Visibility';
import { Box, BoxColumn } from 'src/components/project/explore/summary/helpers';
import TdsReadOnly from 'src/components/tds/TdsReadOnly';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import usePropertyFilters from 'src/components/project/hooks/usePropertyFilters';

export default function BalesTdsRow({ tds }) {
  const id = tds.sku || tds.tds_id.slice(0, 8);

  const [viewingTds, setViewingTds] = useState();

  const propertyFilters = usePropertyFilters();

  return (
    <>
      <Modal
        width={900}
        open={viewingTds}
        onCancel={() => setViewingTds(false)}
        footer={null}
        title={tds.title}
      >
        <TdsReadOnly id={tds.tds_id} />
      </Modal>
      <Row
        className="explore-suppliers-table--row--tds-content"
        gutter={8}
        align="middle"
      >
        <Col style={{ marginRight: 'auto' }}>
          <Button size="small" onClick={() => setViewingTds(true)}>
            <VisibilityIcon />
          </Button>
          &nbsp;&nbsp;
          <Typography.Text className="heavy sm">{id}</Typography.Text>
        </Col>
        {Object.keys(propertyFilters).map((propertyCode) => (
          <PropertyCompare
            key={propertyCode}
            propertyCode={propertyCode}
            propertyFilter={propertyFilters[propertyCode]}
            tds={tds}
          />
        ))}
      </Row>
    </>
  );
}
BalesTdsRow.propTypes = {
  tds: PropTypes.object
};

export function PropertyTdsRowHeaderCell({ propertyCode }) {
  return (
    <BoxColumn width={130}>
      <Typography.Text className="font-size-1 lh-12">
        <MetaPropertyName property={propertyCode} />
      </Typography.Text>
    </BoxColumn>
  );
}
PropertyTdsRowHeaderCell.propTypes = {
  propertyCode: PropTypes.number
};

export function BalesTdsRowHeader() {
  const propertyFilters = usePropertyFilters();

  return (
    <Row gutter={8} className="explore-suppliers-table--row--tds-headers">
      <Col style={{ marginRight: 'auto' }}>
        <Typography.Text className="heavy sm">Matching Records</Typography.Text>
      </Col>
      {Object.keys(propertyFilters).map((propertyCode) => (
        <PropertyTdsRowHeaderCell
          key={propertyCode}
          propertyCode={propertyCode}
        />
      ))}
    </Row>
  );
}

export function PropertyCompare({ propertyCode, propertyFilter, tds }) {
  const tdsPropertyValues = tds[`property_${propertyCode}_code`];

  const matchingValues = Array.isArray(tdsPropertyValues)
    ? tdsPropertyValues.filter(
        (tdsValue) =>
          Array.isArray(propertyFilter) && propertyFilter.includes(tdsValue)
      )
    : [];
  const match = !!matchingValues.length;

  const isEmpty =
    !Array.isArray(tdsPropertyValues) ||
    !tdsPropertyValues.length ||
    tdsPropertyValues.every((value) => !value);

  return (
    <BoxColumn width={120}>
      <Box type={isEmpty ? 'neutral' : match ? 'success' : 'danger'}>
        <Typography.Text className="heavy font-size-2">
          {isEmpty ? (
            '--'
          ) : (
            <MetaPropertyName
              key={`${propertyCode}-${
                (match ? matchingValues : tdsPropertyValues)[0]
              }`}
              property={propertyCode}
              optionValue={(match ? matchingValues : tdsPropertyValues)[0]}
            />
          )}
        </Typography.Text>
      </Box>
    </BoxColumn>
  );
}
PropertyCompare.propTypes = {
  tds: PropTypes.object,
  propertyFilter: PropTypes.object,
  propertyCode: PropTypes.string
};
