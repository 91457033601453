import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ParentSize } from '@visx/responsive';
import { Col, Divider, Form, InputNumber, Row, Typography } from 'antd';

import './HeatmapFromFilters.less';

import { getKpiHeatmap } from 'src/Query/warehouse';
import Heatmap from 'src/components/viz/Heatmap';
import {
  KPIS_WITH_TEST_CONDITIONS,
  KpiSelect,
  KpiTestConditionsSelect
} from 'src/components/project/explore/filters/KpiFilter';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import useMetaNumericalProperty from 'src/hooks/useMetaNumericalProperty';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { useSearchParams } from 'react-router-dom';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';

// TODO: Remove
// function arePropertiesEqual(obj1, obj2, properties) {
//   return !properties.some((prop) => obj1?.[prop] !== obj2?.[prop]);
// }

function HeatmapFromFilters() {
  const filters = useRelevantFiltersForForm();
  const [heatmapFilters, setHeatmapFilters] = useConciergeContextState([
    'explore',
    'projectSettings',
    'heatmap_filters'
  ]);
  const [searchParams] = useSearchParams();
  const projectUuid = searchParams.get('project');

  const kpis = filters?.kpis || {};
  const kpiCodes = Object.keys(kpis);
  const kpiDefaultX = kpiCodes[0];
  const kpiDefaultY = kpiCodes[1];

  const {
    kpi: kpi1,
    values: kpi1Values,
    min: min1,
    max: max1,
    FilterComponent: KpiFilter1
  } = useHeatmapKpiFilter(kpiDefaultX, heatmapFilters ?? {});
  const [kpi1Filter, setKpi1Filter] = useConciergeContextState([
    'explore',
    'filters',
    'kpis',
    kpi1
  ]);
  const [kpi1FilterExpanded, setKpi1FilterExpanded] = useConciergeContextState([
    'explore',
    'expandedFilters',
    'kpis',
    kpi1
  ]);

  const {
    kpi: kpi2,
    values: kpi2Values,
    min: min2,
    max: max2,
    FilterComponent: KpiFilter2
  } = useHeatmapKpiFilter(kpiDefaultY, heatmapFilters ?? {});
  const [kpi2Filter, setKpi2Filter] = useConciergeContextState([
    'explore',
    'filters',
    'kpis',
    kpi2
  ]);
  const [kpi2FilterExpanded, setKpi2FilterExpanded] = useConciergeContextState([
    'explore',
    'expandedFilters',
    'kpis',
    kpi2
  ]);

  useEffect(() => {
    const kpi1Filters = {
      max: max1,
      min: min1,
      test_conditions: kpi1Values?.test_conditions
    };

    Object.keys(kpi1Filters).forEach((key) => {
      if (kpi1Filters[key] === undefined) delete kpi1Filters[key];
    });

    const kpi2Filters = {
      max: max2,
      min: min2,
      test_conditions: kpi2Values?.test_conditions
    };

    Object.keys(kpi2Filters).forEach((key) => {
      if (kpi2Filters[key] === undefined) delete kpi2Filters[key];
    });

    const newHeatmapFilters = {};

    if (Object.keys(kpi1Filters).length) {
      newHeatmapFilters[kpi1] = kpi1Filters;
    } else {
      delete newHeatmapFilters[kpi1];
    }

    if (Object.keys(kpi2Filters).length) {
      newHeatmapFilters[kpi2] = kpi2Filters;
    } else {
      delete newHeatmapFilters[kpi2];
    }

    setHeatmapFilters((prevHeatmapFilters) => ({
      ...prevHeatmapFilters,
      ...newHeatmapFilters
    }));
  }, [kpi1, kpi1Values, kpi2, kpi2Values, max1, max2, min1, min2, projectUuid]);

  const kpi1Obj = useMetaNumericalProperty(kpi1);
  const kpi2Obj = useMetaNumericalProperty(kpi2);
  const unitsX = kpi1Obj?.units ? kpi1Obj?.units : '';
  const unitsY = kpi2Obj?.units ? kpi2Obj?.units : '';
  const pcrbv = kpi1 !== 'PCRBV' && kpi2 !== 'PCRBV' && filters?.kpis?.PCRBV;

  const filtersWithOptions = {
    ...filters,
    kpis: {
      PCRBV: pcrbv,
      [kpi1]: kpi1Values || {},
      [kpi2]: kpi2Values || {}
    },
    heatmap_options: {
      width: 50,
      height: 20,
      kpi_x: kpi1,
      min_x: Number.isNaN(min1) ? undefined : Number(min1),
      max_x: Number.isNaN(max1) ? undefined : Number(max1),

      kpi_y: kpi2,
      min_y: Number.isNaN(min2) ? undefined : Number(min2),
      max_y: Number.isNaN(max2) ? undefined : Number(max2)
    }
  };

  if ('kpi_operator' in filtersWithOptions) {
    // TODO: Temporarily removing the kpi_operator breaking the heatmap-layers endpoint
    delete filtersWithOptions.kpi_operator;
  }

  const { data } = useQuery(
    ['warehouse', 'heatmap', filtersWithOptions],
    () => getKpiHeatmap(filtersWithOptions),
    { retry: false, enabled: !!(kpi1 && kpi2) }
  );

  const targetX = [kpis[kpi1]?.min, kpis[kpi1]?.max];
  const targetY = [kpis[kpi2]?.min, kpis[kpi2]?.max];
  const legendWidth = 50;
  return (
    <div className="kpi-heatmap">
      <Row wrap={false}>
        <Col flex="280px" className="chart-controls">
          <Typography.Text className="sm heavy">X-Axis</Typography.Text>
          {KpiFilter1}
          <Divider />
          <Typography.Text className="sm heavy">Y-Axis</Typography.Text>
          {KpiFilter2}
        </Col>
        <Col flex="1">
          {(!!kpi1 || !!kpi2) && (
            <div className="heatmap-container">
              <ParentSize>
                {(parent) => (
                  <Heatmap
                    width={
                      Math.min(900 + legendWidth, parent.width - 100) -
                      legendWidth
                    }
                    height={Math.min(400, Math.round(parent.width * (17 / 32)))}
                    kpiX={kpi1}
                    kpiY={kpi2}
                    unitsX={unitsX}
                    unitsY={unitsY}
                    data={data}
                    targetX={targetX}
                    onTargetXChange={([min, max]) => {
                      setKpi1Filter({
                        ...kpi1Filter,
                        min,
                        max
                      });
                    }}
                    targetY={targetY}
                    onTargetYChange={([min, max]) => {
                      setKpi2Filter({
                        ...kpi2Filter,
                        min,
                        max
                      });
                    }}
                  />
                )}
              </ParentSize>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default HeatmapFromFilters;

function useHeatmapKpiFilter(defaultKpi, settings) {
  const [kpi, setKpi] = useState(defaultKpi);
  const [prevKpi, setPrevKpi] = useState(null);
  const [values, setValues] = useState();
  const [min, setMin] = useState();
  const [max, setMax] = useState();

  const [form] = Form.useForm();

  useEffect(() => {
    if (!kpi && defaultKpi) setKpi(defaultKpi);
  }, [defaultKpi]);

  useEffect(() => {
    if (!kpi || kpi === prevKpi) return;
    const newTestCondition = settings[kpi]?.test_conditions ?? undefined;
    setValues(newTestCondition);
    const newMax = settings[kpi]?.max ?? undefined;
    setMax(newMax);
    const newMin = settings[kpi]?.min ?? undefined;
    setMin(newMin);

    form.setFieldsValue({
      test_conditions: newTestCondition,
      max: newMax,
      min: newMin
    });

    setPrevKpi(kpi);
  }, [kpi, prevKpi, setPrevKpi, settings]);

  const kpiObj = useMetaNumericalProperty(kpi);

  const onSelectKpi = (selectedKpi) => {
    setPrevKpi(kpi);
    setKpi(selectedKpi);
  };

  // This is to prevent that some selects the same KPI for both axis
  // const filterAvailableKpi1s = (kpiGroup, kpi) => kpi.code !== kpi2;

  const kpiSelectProps = {
    value: kpi
  };
  return {
    kpi,
    values,
    min,
    max,
    FilterComponent: (
      <ExplorePanel className="kpi-filter" pad={12}>
        <KpiSelect
          onSelect={onSelectKpi}
          // filter={filterAvailableKpis}
          selectProps={kpiSelectProps}
        />
        <Form
          form={form}
          onValuesChange={(_, { min: newMin, max: newMax, ...newValues }) => {
            setValues({
              ...newValues,
              unit_type: kpiObj?.unit_type
            });
            setMin(newMin);
            setMax(newMax);
          }}
        >
          <Row gutter={[4, 0]}>
            <Col className="minmax" span={12}>
              <div>Min</div>
              <Form.Item noStyle name="min">
                <InputNumber
                  size="small"
                  type="number"
                  placeholder="No min"
                  controls={false}
                />
              </Form.Item>
            </Col>
            <Col className="minmax" span={12}>
              <div>Max</div>
              <Form.Item noStyle name="max">
                <InputNumber
                  size="small"
                  type="number"
                  placeholder="No max"
                  controls={false}
                />
              </Form.Item>
            </Col>
            {KPIS_WITH_TEST_CONDITIONS.includes(kpi) && (
              <Col className="minmax" span={24}>
                <div>Test Conditions</div>
                <Form.Item noStyle name="test_conditions">
                  <KpiTestConditionsSelect
                    style={{ marginBottom: 8 }}
                    kpiCode={kpi}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </ExplorePanel>
    )
  };
}
