import React, { useMemo, useState } from 'react';

import ExploreSection from 'src/components/project/explore/ExploreSection';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { useMapPageWarehouseSupplier } from 'src/pages/maps/MapPageWarehouseSupplier';
import { Button, Flex, Input, Radio } from 'antd';
import {
  CheckCircleFilled,
  DownloadOutlined,
  SearchOutlined,
  TableOutlined
} from '@ant-design/icons';
import Visible from 'src/components/layout/Visible';
import MapIcon from 'src/components/icons/MapIcon';
import SearchCircularIcon from 'src/components/icons/SearchCircular';
import Suppliers from 'src/components/project/explore/Suppliers';
import SelectWithDecorators from 'src/components/form/SelectWithDecorators';
import useIsBalesMode from 'src/components/project/explore/hooks/useIsBalesMode';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import usePropertyFilters from 'src/components/project/hooks/usePropertyFilters';
import Tag from './supplier-list/Tag';

const filterStatusOptions = [
  {
    value: 'all',
    label: 'All Suppliers'
  },
  {
    value: 'accepted',
    label: 'Accepted Suppliers'
  },
  {
    value: 'removed',
    label: 'Removed Suppliers'
  }
];

/* eslint-disable react/no-unstable-nested-components */

export default function Location() {
  const [page, setPage] = useState(1);
  const [view, setView] = useState('table');
  const [filterResultValue, setFilterResultValue] = useState('all');
  const [filterMatchValue, setFilterMatchValue] = useState([]);
  const [companySearch, setCompanySearch] = useState();
  const balesMode = useIsBalesMode();

  const [filterMatchOptions, filterMatchDetails] =
    useFilterMapOptionsAndDetails();

  const resultIndexFilter = useMemo(
    () => [
      ...new Set(
        filterMatchValue
          .flatMap((v) => filterMatchDetails[v]?.resultIndices)
          .filter((o) => o !== undefined)
      ).keys()
    ],
    [filterMatchValue]
  );

  const parseFilters = useMemo(
    () => (filters, index) => {
      const propertiesToFilterOn = filterMatchValue.filter(
        (v) => filterMatchDetails[v]?.isPropertyFilter
      );
      const allPropertyFilters = filters?.kpis_properties?.properties || {};

      if (
        !propertiesToFilterOn.length ||
        !Object.keys(allPropertyFilters).length
      )
        return filters;

      const necessaryProperties = propertiesToFilterOn.reduce(
        (obj, propertyCode) => {
          if (allPropertyFilters[propertyCode]) {
            // eslint-disable-next-line no-param-reassign
            obj[propertyCode] = {
              ...allPropertyFilters[propertyCode],
              or_group: 'necessary'
            };
          }
          return obj;
        },
        {}
      );

      const optionalProperties = Object.keys(allPropertyFilters).reduce(
        (obj, propertyCode) => {
          if (!necessaryProperties[propertyCode]) {
            // eslint-disable-next-line no-param-reassign
            obj[propertyCode] = {
              ...allPropertyFilters[propertyCode],
              or_group: 'optional'
            };
          }

          return obj;
        },
        {}
      );

      const maxMinMatch = Math.min(Object.keys(necessaryProperties).length, 3);

      return {
        ...(filters || {}),
        kpis_properties: {
          properties: {
            ...necessaryProperties,
            ...optionalProperties
          },
          or_groups: {
            necessary: {
              min_match: index >= maxMinMatch ? 1 : maxMinMatch - index
            },
            optional: {
              min_match: 0
            }
          }
        }
      };
    },
    [filterMatchValue]
  );

  const supplierFilters = useMemo(
    () => ({
      filterResultValue,
      companySearch,
      resultIndexFilter,
      parseFilters,
      page,
      setPage
    }),
    [filterResultValue, companySearch, resultIndexFilter, parseFilters, page]
  );

  const { MapComponent } = useMapPageWarehouseSupplier({
    useMapProps: { theme: 'light_new' },
    className: `explore-map ${balesMode ? 'bales' : ''}`,
    supplierFilters
  });

  const download = () => {};

  const filterStatusChanged = (v) => {
    setPage(1);
    setFilterResultValue(v);
  };

  const filterMatchChanged = (v) => {
    setPage(1);
    setFilterMatchValue(v);
  };

  const companySearchChanged = (e) => {
    setCompanySearch(e.target.value);
    setPage(1);
  };

  return (
    <>
      <Visible lazy visible={balesMode}>
        <ExplorePanel className="mb-xl" style={{ height: 500 }}>
          {MapComponent}
        </ExplorePanel>
      </Visible>
      <Flex
        className="explore-suppliers--controls my-sm"
        justify="space-between"
      >
        <Flex gap={8} direction="horizontal">
          <Input
            allowClear
            size="small"
            placeholder="Search for supplier"
            value={companySearch}
            onChange={companySearchChanged}
            suffix={<SearchOutlined />}
          />
          <SelectWithDecorators
            style={{
              minWidth: 210,
              height: 32
            }}
            Icon={CheckCircleFilled}
            onChange={filterStatusChanged}
            value={filterResultValue}
            options={filterStatusOptions}
          />
          <SelectWithDecorators
            style={{
              minWidth: 400,
              height: 32
            }}
            mode="multiple"
            Icon={SearchCircularIcon}
            onChange={filterMatchChanged}
            value={filterMatchValue}
            placeholder="Specifications"
            labelRender={(v) => (
              <Tag
                text={filterMatchDetails[v.value]?.label}
                type={filterMatchDetails[v.value]?.type}
                highlight={filterMatchDetails[v.value]?.highlight}
              />
            )}
            optionRender={(option) => (
              <Tag
                text={filterMatchDetails[option.value]?.label}
                type={filterMatchDetails[option.value]?.type}
                highlight={filterMatchDetails[option.value]?.highlight}
              />
            )}
            options={filterMatchOptions}
          />
        </Flex>

        <Flex gap={8} direction="horizontal">
          <Button onClick={download} size="small">
            <DownloadOutlined />
          </Button>
          {!balesMode && (
            <Radio.Group
              onChange={(e) => setView(e.target.value)}
              value={view}
              size="small"
            >
              <Radio.Button value="table">
                <TableOutlined />
              </Radio.Button>
              <Radio.Button value="map">
                <MapIcon />
              </Radio.Button>
            </Radio.Group>
          )}
        </Flex>
      </Flex>
      <Visible lazy visible={view === 'map'}>
        <div
          className="explore-map-controls my-sm"
          style={{ height: 32, width: 32 }}
        />
        <ExplorePanel className="mb-lg" style={{ height: 500 }}>
          {MapComponent}
        </ExplorePanel>
      </Visible>

      <Visible visible={view === 'table'}>
        <ExploreSection>
          <Suppliers supplierFilters={supplierFilters} />
        </ExploreSection>
      </Visible>
    </>
  );
}

const resinFilterMatchOptions = [
  {
    value: 'primaryFull',
    label: 'Primary Full'
  },
  {
    value: 'primaryPartial',
    label: 'Primary Partial'
  },
  {
    value: 'secondaryFull',
    label: 'Secondary Full'
  },
  {
    value: 'secondaryPartial',
    label: 'Secondary Partial'
  },
  {
    value: 'expanded',
    label: 'Expanded'
  }
];

const resinFilterMatchDetails = {
  primaryFull: {
    label: 'Primary',
    type: 'normal',
    highlight: 'high',
    resultIndices: [0, 2, 4]
  },
  primaryPartial: {
    label: 'Primary',
    type: 'normal',
    highlight: 'low',
    resultIndices: [6, 8, 10]
  },
  secondaryFull: {
    label: 'Secondary',
    type: 'normal',
    highlight: 'high',
    resultIndices: [0, 6, 12]
  },
  secondaryPartial: {
    label: 'Secondary',
    type: 'normal',
    highlight: 'low',
    resultIndices: [2, 8, 14]
  },
  expanded: {
    label: 'Expanded',
    type: 'alert',
    highlight: 'high',
    resultIndices: [1, 3, 5, 7, 9, 11, 13, 15]
  }
};

const EMPTY_VALUE = {};

function useFilterMapOptionsAndDetails() {
  const isBales = useIsBalesMode();
  const properties = usePropertyFilters() || EMPTY_VALUE;

  if (!isBales) return [resinFilterMatchOptions, resinFilterMatchDetails];

  const propertyCodes = Object.keys(properties);

  const balesFilterMatchOptions = propertyCodes.map((code) => ({
    label: <MetaPropertyName property={code} />,
    value: code
  }));

  const balesFilterMatchDetails = propertyCodes.reduce((obj, code) => {
    // eslint-disable-next-line no-param-reassign
    obj[code] = {
      label: <MetaPropertyName property={code} />,
      type: 'normal',
      highlight: 'high',
      isPropertyFilter: true
    };

    return obj;
  }, {});

  return [balesFilterMatchOptions, balesFilterMatchDetails];
}
