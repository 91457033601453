import PropTypes from 'prop-types';
import { Col, Row, Table, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { sortableColumnTitle } from 'src/pages/concierge/ProposalBuilder/columns/usePbColumns';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';
import { Box } from 'src/components/project/explore/summary/helpers';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import { PropertyCompare } from 'src/components/project/explore/supplier-list/BalesTdsRow';
import {
  KpiRangeCompare,
  ValueCompare
} from 'src/components/project/explore/supplier-list/TdsRow';
import { currencyValuePrice } from 'src/utils/currency';

export default function TdsTable({ tdss, referenceValues }) {
  const [selectedRows, setSelectedRows] = useState([]);

  const firstTds = tdss?.[0];

  const kpiColumns = useMemo(() => {
    const kpiCodes = new Set(
      firstTds
        ? Object.keys(firstTds)
            .filter((tdsAttr) => tdsAttr.startsWith('kpi_'))
            .map((tdsAttr) => {
              const match = tdsAttr.match(/kpi_(.+?)\./);
              return match[1];
            })
        : []
    );

    return [...kpiCodes].map((kpiCode) => {
      const kpiKey = `kpi_${kpiCode}`;

      return {
        title: sortableColumnTitle(kpiCode, `${kpiKey}`),
        sorter: true,
        dataIndex: `${kpiKey}.code`,
        sortKey: `${kpiKey}`,
        key: kpiCode,
        width: 120,
        render: (_, tdsObj) => {
          const kpiFilter = referenceValues?.kpis?.[kpiCode];

          return (
            <KpiRangeCompare
              kpiCode={kpiCode}
              tds={tdsObj}
              kpiFilter={kpiFilter}
            />
          );
        }
      };
    });
  }, [tdss]);

  const propertyColumns = useMemo(() => {
    const propertyCodes = new Set(
      firstTds
        ? Object.keys(firstTds)
            .filter((tdsAttr) => tdsAttr.startsWith('property_'))
            .map((tdsAttr) => {
              const match = tdsAttr.match(/property_(.+?)_/);
              return match[1];
            })
        : []
    );

    return [...propertyCodes].map((propertyCode) => {
      const propertyKey = `property_${propertyCode}`;
      const propertyFilter = referenceValues?.properties?.[propertyCode];

      return {
        title: sortableColumnTitle(
          <MetaPropertyName property={propertyCode} />,
          `${propertyKey}`
        ),
        sorter: true,
        dataIndex: `${propertyKey}.code`,
        sortKey: `${propertyKey}`,
        key: propertyCode,
        width: 120,
        render: (_, tdsObj) => (
          <PropertyCompare
            tds={tdsObj}
            propertyCode={propertyCode}
            propertyFilter={propertyFilter}
          />
        )
      };
    });
  }, [tdss]);

  const priceColumns = useMemo(
    () => [
      {
        title: 'Price',
        sorter: true,
        dataIndex: `price.price`,
        sortKey: 'price',
        key: 'price',
        width: 120,
        render: (_, tdsObj) => (
          <ValueCompare
            label={
              tdsObj?.price?.price
                ? currencyValuePrice(
                    tdsObj?.price?.price,
                    tdsObj?.price?.currency,
                    tdsObj?.price?.units
                  )
                : '-'
            }
            type="neutral"
          />
        )
      }
    ],
    [tdss]
  );

  useEffect(() => {
    setSelectedRows([]);
  }, [tdss]);

  return (
    <Table
      className="search-results--table tds-list"
      rowKey="id"
      columns={[
        {
          title: sortableColumnTitle('Supplier name', 'company_name'),
          sorter: true,
          sortKey: 'company_name',
          dataIndex: 'company_name',
          fixed: 'left',
          width: 339,
          render: (companyName, tdsObj) => (
            <Row justify="space-between" wrap={false} gutter={[16, 16]}>
              <Col>
                <span className="company">
                  <a
                    href={`/supplier/${tdsObj.company_id}`}
                    title={`${tdsObj.company_name} profile`}
                    target="_blank"
                  >
                    {companyName}
                  </a>
                  {tdsObj.sku ? (
                    <>
                      {' '}
                      (<Typography.Text italic>{tdsObj.sku}</Typography.Text>)
                    </>
                  ) : null}
                </span>
                <span className="subcontent">{locationRender(tdsObj)}</span>
              </Col>
            </Row>
          )
        },
        {
          title: sortableColumnTitle('Type', 'type'),
          sorter: true,
          sortKey: 'type_code',
          width: 120,
          dataIndex: 'type',
          render: (val) => <Box>{val}</Box>
        },
        {
          title: sortableColumnTitle('Form', 'form'),
          sorter: true,
          sortKey: 'form_code',
          width: 120,
          dataIndex: 'form',
          render: (val) => <Box>{val}</Box>
        },
        {
          title: sortableColumnTitle('Quantity', 'capacity'),
          sorter: true,
          dataIndex: 'capacity',
          width: 120,
          render: (cap, tdsObj) => (
            <Box>
              {cap} {tdsObj.capacity_units}
            </Box>
          )
        },
        ...kpiColumns,
        ...propertyColumns,
        ...priceColumns
      ]}
      dataSource={tdss || []}
      rowSelection={{
        type: 'checkbox',
        onChange: (_, newSelectedRows) => setSelectedRows(newSelectedRows),
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedRows.map(({ id }) => id)
      }}
      scroll={{
        scrollToFirstRowOnChange: true,
        x: 'scroll'
      }}
      // sticky
    />
  );
}
TdsTable.propTypes = {
  tdss: PropTypes.array,
  referenceValues: PropTypes.object
};
