import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

export default function MarketInsights() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 4000);
  }, []);

  return (
    <>
      {loading && (
        <Skeleton
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100vh',
            background: 'white'
          }}
        />
      )}
      <iframe
        title="MarketInsights Prototype"
        style={{
          width: '100%',
          height: '100vh'
        }}
        src={`https://www.figma.com/embed?${new URLSearchParams({
          embed_host: 'share',
          'hide-ui': 1,
          'show-proto-sidebar': 0,
          'hotspot-hints': 0,
          scaling: 'scale-down-width',
          url: 'https://www.figma.com/proto/punoEYQKZS7evCuMZCBvvH/Supply-%26-Demand?page-id=1%3A13&node-id=2312-3260&node-type=frame&viewport=-4598%2C583%2C0.6&t=MVet1fLHeUHKw80s-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=2312%3A3260'
        }).toString()}`}
      />
    </>
  );
}
