import { fetch } from 'src/utils/client';

const forcedQueryParams = ['page', 'page_size', 'search', 'limit', 'offset'];

export const getTDS = (id) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/${id}/`
  );

export const getTDSAnalytics = (id, data) =>
  fetch(
    `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/${id}/analytics/`,
    {
      method: 'POST',
      body: JSON.stringify(data)
    }
  );

// All of these support either POST or GET for querying.
// POST is more convenient bc it allows us to easily pass complex filters
export const getTDSs = (data, usePost = true) => {
  if (usePost) {
    let paginationQuery = '';
    if (forcedQueryParams.some((param) => data?.[param])) {
      const queryParamsObject = {};
      forcedQueryParams.forEach((param) => {
        if (data?.[param]) queryParamsObject[param] = data[param];
      });

      paginationQuery = new URLSearchParams(queryParamsObject).toString();
    }
    return fetch(
      `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/analytics/?${paginationQuery}`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      }
    );
  }

  return fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/warehouse/technical-data-sheet/?${new URLSearchParams(data).toString()}`
  );
};

export const getExploreSummary = getOrPostEndpoint(
  `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/analytics/summary/`
);

export const getTdsExplain = getOrPostEndpoint(
  `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/analytics/explain/`
);

export const getTdsCsv = (data, usePost = true) => {
  if (usePost) {
    let paginationQuery = '';
    if (forcedQueryParams.some((param) => data?.[param])) {
      const queryParamsObject = {};
      forcedQueryParams.forEach((param) => {
        if (data?.[param]) queryParamsObject[param] = data[param];
      });

      paginationQuery = new URLSearchParams(queryParamsObject).toString();
    }
    return fetch(
      `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/analytics/?${paginationQuery}`,
      {
        method: 'POST',
        headers: { Accept: 'text/csv' },
        body: JSON.stringify(data)
      }
    );
  }

  return fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/warehouse/technical-data-sheet/?${new URLSearchParams(data).toString()}`,
    {
      method: 'GET',
      headers: { Accept: 'text/csv' }
    }
  );
};

export const getKpiAnalytics = (data, usePost = true) =>
  usePost
    ? fetch(
        `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/analytics/kpi-analytics/`,
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
    : fetch(
        `${
          process.env.REACT_APP_CIRCLE_API
        }/warehouse/technical-data-sheet/analytics/kpi-analytics/?${new URLSearchParams(
          data
        ).toString()}`
      );

export const getRegionalAnalytics = (data, usePost = true) =>
  usePost
    ? fetch(
        `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/analytics/regional-analytics/`,
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
    : fetch(
        `${
          process.env.REACT_APP_CIRCLE_API
        }/warehouse/technical-data-sheet/analytics/regional-analytics/?${new URLSearchParams(
          data
        ).toString()}`
      );

// All of these support either POST or GET for querying.
// POST is more convenient bc it allows us to easily pass complex filters
export const getSupplierCapabilities = (data, usePost = true) => {
  if (usePost) {
    let paginationQuery = '';
    if (forcedQueryParams.some((param) => data?.[param])) {
      const queryParamsObject = {};
      forcedQueryParams.forEach((param) => {
        if (data?.[param]) queryParamsObject[param] = data[param];
      });

      paginationQuery = new URLSearchParams(queryParamsObject).toString();
    }
    return fetch(
      `${process.env.REACT_APP_CIRCLE_API}/warehouse/supplier-capability/?${paginationQuery}`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      }
    );
  }

  return fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/warehouse/supplier-capability/?${new URLSearchParams(data).toString()}`
  );
};

export const getGeoAnalytics = (data, usePost = true) =>
  usePost
    ? fetch(
        `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/geo-analytics/`,
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
    : fetch(
        `${
          process.env.REACT_APP_CIRCLE_API
        }/warehouse/technical-data-sheet/geo-analytics/?${new URLSearchParams(
          data
        ).toString()}`
      );

export const getSupplierGeoAnalytics = (data, usePost = true) =>
  usePost
    ? fetch(
        `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/suppliers/geo-analytics/`,
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
    : fetch(
        `${
          process.env.REACT_APP_CIRCLE_API
        }/warehouse/technical-data-sheet/suppliers/geo-analytics/?${new URLSearchParams(
          data
        ).toString()}`
      );

export const getKpiHeatmap = (data, usePost = true) =>
  usePost
    ? fetch(
        `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/analytics/heatmap-layers/`,
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
    : fetch(
        `${
          process.env.REACT_APP_CIRCLE_API
        }/warehouse/technical-data-sheet/heatmap-layers/?${new URLSearchParams(
          data
        ).toString()}`
      );

function getOrPostEndpoint(url) {
  const urlWithQuestionMark = url.endsWith('?') ? url : `${url}?`;

  return (data, usePost = true) => {
    if (usePost) {
      let paginationQuery = '';
      if (forcedQueryParams.some((param) => data?.[param])) {
        const queryParamsObject = {};
        forcedQueryParams.forEach((param) => {
          if (data?.[param]) queryParamsObject[param] = data[param];
        });

        paginationQuery = new URLSearchParams(queryParamsObject).toString();
      }

      return fetch(`${urlWithQuestionMark}${paginationQuery}`, {
        method: 'POST',
        body: JSON.stringify(data)
      });
    }

    return fetch(
      `${urlWithQuestionMark}${new URLSearchParams(data).toString()}`
    );
  };
}

export const getTdsListBySupplier = (data, usePost = true) => {
  if (usePost) {
    let paginationQuery = '';
    if (forcedQueryParams.some((param) => data?.[param])) {
      const queryParamsObject = {};
      forcedQueryParams.forEach((param) => {
        if (data?.[param]) queryParamsObject[param] = data[param];
      });

      paginationQuery = new URLSearchParams(queryParamsObject).toString();
    }
    return fetch(
      `${process.env.REACT_APP_CIRCLE_API}/warehouse/technical-data-sheet/suppliers/?${paginationQuery}`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      }
    );
  }

  return fetch(
    `${
      process.env.REACT_APP_CIRCLE_API
    }/warehouse/technical-data-sheet/suppliers/?${new URLSearchParams(
      data
    ).toString()}`
  );
};
