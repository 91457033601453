import React, { useCallback, useMemo, useState } from 'react';
import {
  Avatar,
  Button,
  Col,
  Divider,
  Flex,
  Row,
  Space,
  Tabs,
  Tag,
  Typography
} from 'antd';
import { IconClose } from '@aws-amplify/ui-react/internal';
import { Link, useSearchParams } from 'react-router-dom';
import {
  CheckCircleFilled,
  LinkOutlined,
  UserOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import {
  KpiName,
  useKpiUnit,
  useKpiUnitType
} from 'src/components/project/explore/filters/KpiFilter';
import useIsConcierge from 'src/hooks/useIsConcierge';
import useUpdateProject from 'src/Mutation/useUpdateProject';
import { findProperty, isTdsFoodGrade } from 'src/utils/properties';
import prettyNumber from 'src/components/utils/prettyNumber';
import dayjs from 'dayjs';
import useMetaPropertyOptionNameFromCode from 'src/hooks/useMetaPropertyOptionNameFromCode';
import useGetPopulatedProject from 'src/Query/useGetPopulatedProject';
import numericRange from 'src/components/utils/numericRange';
import { ProjectTDSStatus } from 'src/components/project/search/constants';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';

import './QuoteDetails.less';
import MarkdownEditableText from 'src/utils/MarkdownEditableText';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import { useUnitNormalize } from 'src/hooks/useUnits';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import PriceSummary from './PriceSummary';

const capacityText = (tdsOrProject) =>
  tdsOrProject?.capacity && tdsOrProject.capacity > 0
    ? `${prettyNumber(tdsOrProject.capacity)} ${tdsOrProject.capacity_units}`
    : '--';

const locationText = (loc) => (loc ? locationRender(loc) : '--');

const termsText = (terms) =>
  typeof terms === 'string' || terms instanceof String
    ? terms.toUpperCase()
    : '--';
export function QuoteDetails({ onClose, project, tds }) {
  const [tab, setTab] = useState('spec');
  const isConcierge = useIsConcierge();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  const filters = {
    status: [status || ProjectTDSStatus.PROPOSAL]
  };

  const { mutate, messageContext } = useUpdateProject(project.uuid, filters);

  const { settings = {} } = project ?? {};

  const submittedTds = settings?.submittedTdss?.[tds.uuid] ?? null;

  const editableConfig = useMemo(
    () => (key) => ({
      text: submittedTds?.summary,
      onChange: isConcierge
        ? (val) => {
            const updatedSettings = {
              ...settings,
              submittedTdss: {
                ...(settings.submittedTdss ?? {}),
                [tds.uuid]: {
                  ...(settings.submittedTdss?.[tds.uuid] ?? {}),
                  [key]: val
                }
              }
            };

            mutate({
              uuid: project.uuid,
              settings: updatedSettings
            });
          }
        : undefined
    }),
    [isConcierge, project, settings, tds]
  );

  const items = [
    {
      key: 'spec',
      label: 'Quality',
      children: <SpecTab filters={filters} project={project} tds={tds} />
    },
    {
      key: 'supply',
      label: 'Quantity',
      children: <SupplyTab filters={filters} project={project} tds={tds} />
    },
    {
      key: 'brand',
      label: 'Brand fit',
      children: <BrandTab filters={filters} project={project} tds={tds} />
    },
    {
      key: 'docs',
      label: 'Documents',
      children: <DocsTab tds={tds} />
    }
  ];

  const type = findProperty(tds, 'type');
  const form = findProperty(tds, 'form');

  const tdsCapacity = capacityText(tds);
  const preferredLocation = tds.project_tds?.tds_location || tds.locations[0];
  const preferredLocationText = locationText(preferredLocation);

  const sortedPrices =
    preferredLocation?.price_logs?.sort((pl1, pl2) =>
      dayjs(pl1.date) > dayjs(pl2.date) ? 1 : -1
    ) || [];
  const latestPriceObj = sortedPrices.length
    ? sortedPrices[sortedPrices.length - 1]
    : null;

  const { price: latestPrice = null } = latestPriceObj ?? {};
  const supplierPrice = {
    material: latestPrice,
    tariff: tds.project_tds.tariff,
    freight: tds.project_tds.freight,
    currency: tds.project_tds.currency,
    terms: tds.project_tds.pricing_terms
  };
  const buyerPrice = {
    material: project?.price,
    tariff: project?.target_tariff,
    freight: project?.target_freight,
    currency: project?.currency,
    terms: project?.pricing_terms
  };

  return (
    <Flex className="quote-details" vertical>
      <Flex
        align="center"
        className="quote-details--header mb-xl px-lg py-sm"
        justify="start"
      >
        <div className="mr-sm">
          <button className="bare" onClick={onClose} type="button">
            <IconClose />
          </button>
        </div>
        <Typography.Text>Quote Details</Typography.Text>
      </Flex>
      <Flex className="px-lg" vertical>
        <Flex className="supplier-summary mb-xl">
          <Space>
            <Avatar icon={<UserOutlined />} src={tds.company.avatar} />
            <Flex className="ml-sm" vertical>
              <Space>
                <Typography.Text>{tds.company.name}</Typography.Text>
                {!!submittedTds?.tag && (
                  <Tag color="#2f54eb">{submittedTds?.tag}</Tag>
                )}
              </Space>
              <Typography.Text className="location">
                {preferredLocationText}
              </Typography.Text>
            </Flex>
          </Space>
          <Link
            style={{ alignSelf: 'start', justifySelf: 'end' }}
            to={`/supplier/${tds.company.uuid}`}
          >
            <Button title="View Profile">View Profile</Button>
          </Link>
        </Flex>

        <Flex className="mb-xl quote-generals" gap={16}>
          <Flex vertical>
            <Typography.Text>Material</Typography.Text>
            <Typography.Text strong>{type.value}</Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text>Form</Typography.Text>
            <Typography.Text strong>{form.value}</Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text>Quantity</Typography.Text>
            <Typography.Text strong>{tdsCapacity}</Typography.Text>
          </Flex>
          <Flex vertical>
            <Typography.Text>Destination</Typography.Text>
            <Typography.Text strong>
              {locationsText(project?.locations)}
            </Typography.Text>
          </Flex>
        </Flex>

        <MarkdownEditableText
          className="supplier-assesment mb-xxl"
          {...editableConfig('summary')}
        />

        <PriceSummary
          supplierPrice={supplierPrice}
          buyerPrice={buyerPrice}
          buyerIndex={project.buyer_benchmark}
          units={project.price_weight_units}
        />
        <Tabs
          activeKey={tab}
          rootClassName="px-0"
          onChange={(key) => setTab(key)}
          items={items}
        />
      </Flex>
    </Flex>
  );
}

QuoteDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

function ConciergeEditableCheck({
  filters,
  projectUuid,
  settingsKey,
  tab,
  tdsUuid
}) {
  const isConcierge = useIsConcierge();

  const { data: project } = useGetPopulatedProject(projectUuid, filters);
  const { settings = {} } = project ?? {};

  const checked = useMemo(
    () => settings?.submittedTdss?.[tdsUuid]?.[tab]?.[settingsKey] ?? false,
    [settings, tdsUuid]
  );

  return isConcierge ? (
    <EditableCheckIcon
      filters={filters}
      projectUuid={projectUuid}
      settingsKey={settingsKey}
      tab={tab}
      tdsUuid={tdsUuid}
    />
  ) : checked ? (
    <CheckCircleFilled className="check-circle-filled success mr-xs" />
  ) : (
    <CheckCircleFilled className="check-circle-filled warning mr-xs" />
  );
}

ConciergeEditableCheck.propTypes = {
  filters: PropTypes.object,
  projectUuid: PropTypes.string.isRequired,
  settingsKey: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  tdsUuid: PropTypes.string.isRequired
};

const KpiRow = React.memo(({ filters, kpiCode, project, tds }) => {
  const kpiFilters = project?.filters?.kpis ?? {};
  const kpiFilter = kpiFilters[kpiCode];
  const defaultUnits = useKpiUnit(kpiCode);
  const unitType = useKpiUnitType(kpiCode);
  const unitBuyer = kpiFilter.units || defaultUnits;

  const settingsKey = `${kpiCode.toLowerCase()}_match`;

  const mnp = tds.material_numerical_properties.find(
    (propertyValue) => propertyValue.property.code === kpiCode
  );
  const unitSeller = mnp?.units;

  const [minBuyer, maxBuyer, unitNormalizedBuyer] = useUnitNormalize(
    kpiFilter?.min,
    kpiFilter?.max,
    unitBuyer,
    unitType
  );

  const [minSeller, maxSeller, unitNormalizedSeller] = useUnitNormalize(
    mnp?.min,
    mnp?.max,
    unitSeller,
    unitType,
    unitNormalizedBuyer // override if it exists (for consistency)
  );

  const unit = unitNormalizedBuyer || unitNormalizedSeller;

  return (
    <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
      <Col span={12}>
        <Typography.Text>
          <KpiName codeOrUuid={kpiCode} /> ({unit || ''})
        </Typography.Text>
      </Col>
      <Col span={6}>
        <Typography.Text type="secondary">
          {kpiFilter
            ? numericRange({
                min: minBuyer,
                max: maxBuyer,
                rangeRender: (vMin, vMax) => `${vMin} - ${vMax}`,
                noMaxRender: (v) => `${v} or higher`,
                noMinRender: (v) => `${v} or lower`,
                empty: ''
              })
            : '--'}
        </Typography.Text>
      </Col>
      <Col span={6}>
        {!!mnp && (
          <>
            <ConciergeEditableCheck
              filters={filters}
              projectUuid={project.uuid}
              settingsKey={settingsKey}
              tab="spec"
              tdsUuid={tds.uuid}
            />

            <Typography.Text className="heavy" strong>
              {numericRange({
                min: minSeller,
                max: maxSeller,
                rangeRender: (vMin, vMax) => `${vMin} - ${vMax}`,
                noMaxRender: (v) => `${v} or higher`,
                noMinRender: (v) => `${v} or lower`,
                empty: ''
              })}
            </Typography.Text>
          </>
        )}
      </Col>
    </Row>
  );
});

KpiRow.displayName = 'KpiRow';

KpiRow.propTypes = {
  filters: PropTypes.object,
  kpiCode: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

const KpiTextRow = React.memo(
  ({ filters, project, tds, label, buyer, supplier, key }) => (
    <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
      <Col span={12}>
        <Typography.Text>{label}</Typography.Text>
      </Col>
      <Col span={6}>
        <Typography.Text type="secondary">{buyer}</Typography.Text>
      </Col>
      <Col span={6}>
        <ConciergeEditableCheck
          filters={filters}
          projectUuid={project.uuid}
          settingsKey={key}
          tab="spec"
          tdsUuid={tds.uuid}
        />

        <Typography.Text className="heavy" strong>
          {supplier}
        </Typography.Text>
      </Col>
    </Row>
  )
);

KpiTextRow.displayName = 'KpiTextRow';

KpiTextRow.propTypes = {
  filters: PropTypes.object,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  buyer: PropTypes.string.isRequired,
  supplier: PropTypes.string.isRequired
};

const PropertyRow = React.memo(
  ({ filters, project, tds, propertyCode, override }) => {
    const settingsKey = `${propertyCode.toLowerCase()}_match`;

    const requestedProperties =
      project?.filters?.properties?.[propertyCode] || [];
    const properties = tds.material_properties.filter(
      (propertyValue) => propertyValue.meta_property.code === propertyCode
    );

    return (
      <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
        <Col span={12}>
          <Typography.Text>
            <MetaPropertyName property={propertyCode} />
          </Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text className="heavy" strong>
            {requestedProperties?.length > 0 &&
              requestedProperties
                .map((valueCode) => (
                  <MetaPropertyName
                    key={valueCode}
                    property={propertyCode}
                    optionValue={valueCode}
                  />
                ))
                .reduce((prev, curr) => [prev, ', ', curr])}
          </Typography.Text>
        </Col>
        <Col span={6}>
          <ConciergeEditableCheck
            filters={filters}
            projectUuid={project.uuid}
            settingsKey={settingsKey}
            tab="spec"
            tdsUuid={tds.uuid}
          />

          <Typography.Text className="heavy" strong>
            {override ??
              (properties?.length > 0 &&
                properties
                  .map((property) => (
                    <MetaPropertyName
                      key={property.value}
                      property={propertyCode}
                      optionValue={property.value}
                    />
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr]))}
          </Typography.Text>
        </Col>
      </Row>
    );
  }
);

PropertyRow.displayName = 'PropertyRow';

PropertyRow.propTypes = {
  filters: PropTypes.object,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired,
  propertyCode: PropTypes.string.isRequired,
  override: PropTypes.string
};

function SpecTab({ filters, project, tds }) {
  const kpiFilters = project?.filters?.kpis ?? {};
  const primaryKpis = [];
  const secondaryKpis = [];
  Object.keys(kpiFilters).forEach((code) => {
    if (!kpiFilters[code].allow_null) {
      primaryKpis.push(code);
    } else {
      secondaryKpis.push(code);
    }
  });

  return (
    <div className="spec-tab">
      <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
        <Col span={12}>
          <h6 className="ant-typography heavy mb-0">Primary Specifications</h6>
        </Col>
        <Col span={6}>
          <Typography.Text type="secondary">Requested</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text type="secondary">Supplier</Typography.Text>
        </Col>
      </Row>
      <Flex vertical>
        {primaryKpis.map((kpiCode) => (
          <KpiRow
            filters={filters}
            key={kpiCode}
            kpiCode={kpiCode}
            project={project}
            tds={tds}
          />
        ))}
      </Flex>
      {secondaryKpis.length > 0 && (
        <>
          <Divider className="mt-sm" />
          <h6 className="ant-typography heavy mb">Secondary Specifications</h6>
          <Flex vertical>
            <PropertyRow
              filters={filters}
              key="color_match"
              project={project}
              tds={tds}
              propertyCode="color"
              override={tds.project_tds.color}
            />
            <KpiTextRow
              filters={filters}
              key="feedstock_match"
              project={project}
              tds={tds}
              label="Feedstock"
              buyer={project?.feedstock || '--'}
              supplier={tds?.project_tds?.feedstock || '--'}
            />
            {secondaryKpis.map((kpiCode) => (
              <KpiRow
                filters={filters}
                key={kpiCode}
                kpiCode={kpiCode}
                project={project}
                tds={tds}
              />
            ))}
          </Flex>
        </>
      )}
    </div>
  );
}

SpecTab.propTypes = {
  filters: PropTypes.object,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

const isProjectFoodGrade = (project) =>
  (project?.filters?.properties?.grade || []).includes('food_grade');

const locationsText = (locations) =>
  Array.isArray(locations) ? locations.map(locationRender).join(' | ') : '--';

const booleanText = (b) => (b ? 'Yes' : '--');

function SupplyTab({ filters, project, tds }) {
  const isFoodGrade = isTdsFoodGrade(tds);
  const tdsCapacity = capacityText(tds);
  const projectCapacity = capacityText(project);
  const preferredLocation = useMemo(
    () => tds?.project_tds?.tds_location,
    [tds]
  );

  return (
    <div className="supply-tab">
      <Flex vertical>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8} offset={8}>
            <Typography.Text>Target</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text>Supplier</Typography.Text>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Shipment Origin</Typography.Text>
          </Col>
          <Col span={8}>
            <Flex vertical>
              {(project?.locations ?? []).map((location, idx) => (
                <Typography.Text key={`${locationRender(location)}-${idx}`}>
                  {locationRender(location)}
                </Typography.Text>
              ))}
            </Flex>
          </Col>
          <Col span={8}>
            <Flex vertical>
              {preferredLocation ? (
                <Typography.Text strong>
                  {locationRender(preferredLocation)}
                </Typography.Text>
              ) : (
                (tds?.locations ?? []).map((location, idx) => (
                  <Typography.Text
                    key={`${locationRender(location)}-${idx}`}
                    strong
                  >
                    {locationRender(location)}
                  </Typography.Text>
                ))
              )}
            </Flex>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Capacity</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text>{projectCapacity}</Typography.Text>
          </Col>
          <Col span={8}>
            <ConciergeEditableCheck
              filters={filters}
              projectUuid={project.uuid}
              settingsKey="capacity_match"
              tab="supply"
              tdsUuid={tds.uuid}
            />
            <Typography.Text className="heavy" strong>
              {tdsCapacity}
            </Typography.Text>
          </Col>
        </Row>
      </Flex>
    </div>
  );
}

SupplyTab.propTypes = {
  filters: PropTypes.object,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

function BrandTab({ filters, project, tds }) {
  const yearsInBusiness = tds?.company?.years_in_business || '--';
  const supplierCerts = tds?.material_properties
    .filter((p) => p.meta_property.code === 'certs')
    .map((p) => p.value);
  const projectCerts = useMetaPropertyOptionNameFromCode(
    project?.filters?.properties?.certs
  );
  return (
    <div className="brand-tab">
      <Flex vertical>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8} offset={8}>
            <Typography.Text>Target</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text>Supplier</Typography.Text>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Food grade</Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text>{project.food_grade || '--'}</Typography.Text>
          </Col>
          <Col span={8}>
            <Flex align="start">
              <ConciergeEditableCheck
                filters={filters}
                projectUuid={project.uuid}
                settingsKey="food_grade_match"
                tab="brand"
                tdsUuid={tds.uuid}
              />
              <Flex>
                <Typography.Text className="heavy" strong>
                  {tds.project_tds.food_grade || '--'}
                </Typography.Text>
              </Flex>
            </Flex>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Other certifications</Typography.Text>
          </Col>
          <Col span={8}>
            <Flex vertical>
              {(projectCerts ?? [])
                .filter((cert) => !cert.includes('FDA')) // FIXME temporary fix to exclude food grade
                .map((cert) => (
                  <Typography.Text key={cert} strong>
                    {cert}
                  </Typography.Text>
                ))}
            </Flex>
          </Col>
          <Col span={8}>
            <Flex align="start">
              <ConciergeEditableCheck
                filters={filters}
                projectUuid={project.uuid}
                settingsKey="certs_match"
                tab="brand"
                tdsUuid={tds.uuid}
              />
              <Flex vertical>
                {(supplierCerts ?? [])
                  .filter((cert) => !cert.includes('FDA')) // FIXME temporary fix to exclude food grade
                  .map((cert) => (
                    <Typography.Text key={cert} strong>
                      {cert}
                    </Typography.Text>
                  ))}
              </Flex>
            </Flex>
          </Col>
        </Row>
        <Row className="quote-details-row mb" gutter={[8, 0]} wrap={false}>
          <Col span={8}>
            <Typography.Text>Years in business</Typography.Text>
          </Col>
          <Col span={8}>&nbsp;</Col>
          <Col span={8}>
            <ConciergeEditableCheck
              filters={filters}
              projectUuid={project.uuid}
              settingsKey="years_in_business_match"
              tab="brand"
              tdsUuid={tds.uuid}
            />
            <Typography.Text className="heavy" strong>
              {yearsInBusiness}
            </Typography.Text>
          </Col>
        </Row>
      </Flex>
    </div>
  );
}
BrandTab.propTypes = {
  filters: PropTypes.object,
  project: PropTypes.object.isRequired,
  tds: PropTypes.object.isRequired
};

function DocsTab({ tds }) {
  if (!tds.documents?.length) {
    return <>No documents attached.</>;
  }
  return (
    <div className="brand-tab">
      <Flex vertical>
        {tds.documents.map((doc) => (
          <Row
            key={doc.uuid}
            className="quote-details-row mb"
            gutter={[8, 0]}
            wrap={false}
          >
            <a target="_blank" href={doc.url}>
              <LinkOutlined /> {doc.title}
            </a>
          </Row>
        ))}
      </Flex>
    </div>
  );
}
DocsTab.propTypes = {
  tds: PropTypes.object.isRequired
};

function EditableCheckIcon({
  filters,
  projectUuid,
  settingsKey,
  tab,
  tdsUuid
}) {
  const { data: project } = useGetPopulatedProject(projectUuid, filters);
  const { mutate, messageContext } = useUpdateProject(projectUuid, filters);

  const { settings = {} } = project ?? {};

  const match = useMemo(
    () => settings?.submittedTdss?.[tdsUuid]?.[tab]?.[settingsKey] ?? false,
    [settings, settingsKey, tab, tdsUuid]
  );

  const setMatch = useCallback(
    (newValue) => {
      const submittedTdsSettings = settings?.submittedTdss?.[tdsUuid];
      const updatedSettings = {
        ...settings,
        submittedTdss: {
          ...(settings?.submittedTdss ?? {}),
          [tdsUuid]: {
            ...(submittedTdsSettings ?? {}),
            [tab]: {
              ...submittedTdsSettings?.[tab],
              [settingsKey]: newValue
            }
          }
        }
      };

      mutate({
        uuid: projectUuid,
        settings: updatedSettings
      });
    },
    [projectUuid, settings, settingsKey, tab, tdsUuid]
  );
  const [editable] = useConciergeContextState(['proposal', 'editable']);

  const onClick = editable
    ? (ev) => {
        ev.stopPropagation();
        setMatch(!match);
      }
    : undefined;

  return (
    <>
      {messageContext}
      <CheckCircleFilled
        className={`check-circle-filled mr-xs ${match ? 'success' : 'warning'}`}
        onClick={onClick}
      />
    </>
  );
}

EditableCheckIcon.propTypes = {
  filters: PropTypes.object,
  projectUuid: PropTypes.string.isRequired,
  settingsKey: PropTypes.string.isRequired,
  tab: PropTypes.string,
  tdsUuid: PropTypes.string.isRequired
};
